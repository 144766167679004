import React from 'react'
import './Cart.css'
import Button from '../Button/Button';
function Cart({cartItems, onCheckout}) {

const totalPrice = cartItems.reduce((a,b)=> a + b.price * b.quantity, 0); //функция общей цены

  return (
    <div className="cart__container">
      {cartItems.length === 0 ? "No items in cart" : ""}
      <br /> <span className="">Total Price: ${totalPrice.toFixed(2)}</span>
      <Button
        title={`${cartItems.length === 0 ? "Order !" : "Checkout"} `}
        type={"checkout"}
        disable={cartItems.length === 0}
        onClick={onCheckout}
      />
    </div>
  )
}

export default Cart